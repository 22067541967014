<template>
  <div class="inline">
    <div class="inline" @click="handleOpen">
      <slot name="trigger" />
    </div>
    <div
      :id="drawerId"
      ref="drawerElement"
      class="fixed right-0 top-0 z-50 h-screen w-full translate-x-full overflow-y-auto bg-white p-4 pt-8 transition-transform md:w-1/3"
      tabindex="-1">
      <button
        id="drawer-hide-button"
        :data-drawer-hide="drawerId"
        :aria-controls="drawerId"
        type="button"
        class="absolute end-2.5 top-6 inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
        @click="handleClose()">
        <SvgoXMark class="text-lg text-black" />
        <span class="sr-only">Menü schließen</span>
      </button>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DrawerInterface, DrawerOptions, InstanceOptions } from "flowbite";
import { Drawer } from "flowbite";
import type { Ref } from "vue";

const drawerId = useId();
const drawer: Ref<DrawerInterface | null> = ref(null);
const drawerElement: Ref<HTMLDivElement | null> = ref(null);

onMounted(() => {
  // options with default values
  const options: DrawerOptions = {
    backdrop: true,
    backdropClasses: "bg-gray-900/50 fixed inset-0 z-40",
    bodyScrolling: false,
    edge: false,
    edgeOffset: "",
    onHide() {
      emits("close");
    },
    placement: "right",
  };

  const instanceOptions: InstanceOptions = {
    id: drawerId,
    override: true,
  };

  drawer.value = new Drawer(drawerElement.value, options, instanceOptions);
});

defineExpose({ close: handleClose, open: handleOpen });
const emits = defineEmits<{ close: [] }>();
function handleClose(): void {
  drawer.value?.hide();
}

function handleOpen(): void {
  drawer.value?.show();
}
</script>

<style scoped></style>
